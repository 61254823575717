<template>
  <div
    class="bg-purple-100 -mx-6 md:-mx-10 -mt-6 p-3 pb-0"
    id="wallet-company"
    style="margin-bottom: 0 !important"
  >
    <div class="grid grid-cols-1 md:grid-cols-12 gap-3">
      <div class="col-span-1 md:col-span-9">
        <div class="bg-white px-6 sm:pr-10 sm:pl-16 pt-10 pb-10 mb-0">
          <div class="flex flex-wrap mb-10">
            <h2 class="text-3xl font-bold mr-5">Wallet</h2>
            <button
              type="button"
              class="btn btn-blue btn-sm ml-auto"
              @click="startTransfer"
            >
              <ion-icon name="repeat-outline" class="mr-2 text-xl"></ion-icon>
              <span>Transfer Fund</span>
            </button>
          </div>

          <div
            class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 mb-6"
          >
            <div class="col-span-1">
              <div class="border-b border-blue-200 pb-10 mb-10">
                <div class="mb-2">
                  <div class="badge badge-xs badge-blue">DEBIT</div>
                </div>
                <div class="text-12 text-gray-600 mb-1">Balance</div>
                <template v-if="wallet.loading">
                  <sm-loader class="mb-8" />
                </template>
                <template v-else>
                  <div class="flex flex-row items-center">
                    <div class="text-20 mr-5">
                      ₦ {{ getNominator(debitBalance)
                      }}<span class="text-16"
                        >.{{ getDenominator(debitBalance) }}</span
                      >
                    </div>
                    <button class="btn btn-blue-outline btn-sm">
                      <ion-icon
                        name="add-outline"
                        class="text-base mr-1"
                      ></ion-icon>
                      <span>Top Up</span>
                    </button>
                  </div>
                  <router-link
                    :to="{ name: 'analytics' }"
                    class="text-11 text-gray-500"
                  >
                    <span class="icon-bars mr-2">
                      <span class="bar"></span>
                      <span class="bar"></span>
                      <span class="bar"></span>
                    </span>
                    <span>View Spending</span>
                  </router-link>
                </template>
              </div>
            </div>
            <div class="col-span-1 border-blue-200">
              <div class="border-b border-blue-200 pb-10 mb-10">
                <div class="mb-2">
                  <div class="badge badge-xs badge-green">CREDIT</div>
                </div>
                <div class="text-12 text-gray-600 mb-1">Available Credit</div>
                <template v-if="wallet.loading">
                  <sm-loader class="mb-8" />
                </template>
                <template v-else>
                  <div class="flex flex-row items-center">
                    <div class="text-20 mr-5">
                      ₦ {{ getNominator(availableCredit)
                      }}<span class="text-16"
                        >.{{ getDenominator(availableCredit) }}</span
                      >
                    </div>
                    <button class="btn btn-blue-outline btn-sm">
                      <ion-icon
                        name="caret-up-circle-outline"
                        class="text-base mr-1"
                      ></ion-icon>
                      <span>Increase Limit</span>
                    </button>
                  </div>
                  <router-link
                    :to="{ name: 'analytics' }"
                    class="text-11 text-gray-500"
                  >
                    <span class="icon-bars mr-2">
                      <span class="bar"></span>
                      <span class="bar"></span>
                      <span class="bar"></span>
                    </span>
                    <span>View Analytics</span>
                  </router-link>
                </template>
              </div>
            </div>
          </div>

          <div class="flex items-end mb-10">
            <h3 class="text-xl font-bold line-height" style="line-height: 1">
              Departments
            </h3>
            <router-link
              :to="{ name: 'groups-new' }"
              class="text-blue-800 opacity-75 text-sm ml-3"
              style="line-height: 1"
            >
              + Add a Department
            </router-link>
          </div>

          <template v-if="groups.loading">
            <sm-loader class="mb-16" />
          </template>
          <template v-else>
            <div
              class="grid grid-cols-1 md:grid-cols-12 lg:grid-cols-1 xl:grid-cols-12 gap-4"
            >
              <div
                class="col-span-5 border border-blue-200 px-6 py-4"
                v-if="firstGroup"
              >
                <div class="mb-2 flex flex-row flex-wrap items-center">
                  <div class="text-lg mr-5">
                    ₦ {{ getTotalDebitBalance(firstGroup) | currency }}
                  </div>
                  <span class="badge badge-purple badge-xs px-4 ml-auto">{{
                    firstGroup.name
                  }}</span>
                </div>
                <div class="flex flex-row items-center">
                  <router-link
                    :to="{ name: 'cards', query: { groupId: firstGroup.id } }"
                    class="btn btn-sm btn-blue-outline mr-5"
                  >
                    <ion-icon name="card-outline" class="mr-2" />
                    <span>View Cards</span>
                  </router-link>
                  <template v-if="cards.loading">
                    <sm-loader class="ml-auto" />
                  </template>
                  <template v-else>
                    <router-link
                      :to="{ name: 'cards', query: { groupId: firstGroup.id } }"
                      class="opacity-50 text-blue-900 text-xs ml-auto"
                    >
                      {{ getCardCount(firstGroup) }} Active Cards
                    </router-link>
                  </template>
                </div>
              </div>
              <div
                class="col-span-5 border border-blue-200 px-6 py-4"
                v-if="secondGroup"
              >
                <div class="mb-2 flex flex-row flex-wrap items-center">
                  <div class="text-lg mr-5">
                    ₦ {{ getTotalDebitBalance(secondGroup) | currency }}
                  </div>
                  <span class="badge badge-blue badge-xs px-4 ml-auto">{{
                    secondGroup.name
                  }}</span>
                </div>
                <div class="flex flex-row items-center">
                  <router-link
                    :to="{ name: 'cards', query: { groupId: secondGroup.id } }"
                    class="btn btn-sm btn-blue-outline mr-5"
                  >
                    <ion-icon name="card-outline" class="mr-2" />
                    <span>View Cards</span>
                  </router-link>
                  <template v-if="cards.loading">
                    <sm-loader class="ml-auto" />
                  </template>
                  <template v-else>
                    <router-link
                      :to="{
                        name: 'cards',
                        query: { groupId: secondGroup.id },
                      }"
                      class="opacity-50 text-blue-900 text-xs ml-auto"
                    >
                      {{ getCardCount(secondGroup) }} Active Cards
                    </router-link>
                  </template>
                </div>
              </div>
              <div
                class="col-span-2 flex items-center"
                v-if="groups.data.length > 2"
              >
                <router-link
                  :to="{ name: 'cards' }"
                  class="opacity-50 text-blue-900 text-sm ml-auto"
                >
                  + {{ groups.data.length - 2 }} More
                </router-link>
              </div>
            </div>
          </template>
        </div>

        <div class="bg-white px-6 sm:pr-10 sm:pl-16 py-10">
          <div
            class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 gap-4 md:gap-8 lg:gap-6 xl:gap-16"
          >
            <div class="col-span-1">
              <div class="flex items-center mb-12">
                <h3
                  class="text-xl font-bold line-height"
                  style="line-height: 1"
                >
                  Repayment Cards
                </h3>
                <paystack
                  class="text-blue-800 opacity-75 text-sm ml-3 focus:outline-none"
                  :amount="100"
                  :callback="addCard"
                  :disabled="form.loading"
                >
                  + Add a Card
                </paystack>
              </div>

              <div class="mb-10">
                <template v-if="repaymentCards.loading || form.loading">
                  <sm-loader class="mb-10" />
                </template>
                <template v-else-if="repaymentCards.data.length">
                  <template v-for="(card, n) in repaymentCards.data">
                    <div
                      class="flex flex-row items-center bg-purple-100 px-6 py-4 text-sm relative mb-5"
                      style="letter-spacing: 0.1em"
                      :key="n"
                    >
                      <div
                        class="absolute left-0 w-2px h-20px rounded"
                        :class="{
                          [`bg-${getProvider(card.card_type).color}-500`]: true,
                        }"
                      />
                      <div class="mr-6" style="width: 45px">
                        <img
                          :src="getProvider(card.card_type).logo"
                          alt="Visa"
                          style="height: 26px"
                        />
                      </div>
                      <span
                        class="text-gray-700"
                        v-html="`•••• &nbsp;${card.last4}`"
                      />
                      <span class="text-gray-700 ml-auto"
                        >{{ card.exp_month }}/{{
                          card.exp_year.match(/\d{2}$/)[0]
                        }}</span
                      >
                    </div>
                  </template>
                </template>
                <template v-else>
                  <div class="text-sm text-gray-700 mb-10">
                    You have no repayment cards at the moment. Click the button
                    below to add a new one.
                  </div>
                </template>
              </div>
            </div>
            <div class="col-span-1">
              <h3
                class="text-xl font-bold line-height mb-12"
                style="line-height: 1"
              >
                Bank Transfer
              </h3>

              <div class="flex items-center mb-6">
                <img :src="access" alt="Access Bank" height="54" />
                <div class="text-gray-700 ml-2" style="letter-spacing: 0.05em">
                  <div class="mb-2">0815690344</div>
                  <div class="text-sm">CredNet Technologies Ltd.</div>
                </div>
              </div>

              <button type="button" class="btn btn-blue-outline">
                Notify of Payment
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-span-1 md:col-span-3">
        <div class="bg-blue-100 px-6 sm:px-10 py-10 h-full">
          <template v-if="cards.loading">
            <sm-loader class="mb-16" />
          </template>
          <template v-else-if="lastCard">
            <credit-card
              :name="lastCard.user.name"
              exp-month="03"
              exp-year="22"
              type="corporate"
              class="mb-4"
            />
          </template>
          <template v-else>
            <div class="text-sm text-center text-gray-700 mb-10">
              You don't have any cards yet.
            </div>
          </template>

          <div class="text-center">
            <router-link
              :to="{ name: 'cards-new' }"
              class="btn btn-blue-outline btn-sm"
            >
              <ion-icon name="add-outline" class="text-base mr-1"></ion-icon>
              <span>Create Card</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <transfers-request ref="transferRequest" />

    <modal
      className="w-full md:w-2/5 xl:w-3/10 text-center pt-24"
      ref="successModal"
    >
      <img
        :src="assets.icons.lg.checkmark"
        class="mx-auto mb-8"
        height="90"
        alt="Check Mark"
      />

      <div class="text-sm mb-10">Card Saved successfully!</div>

      <button
        type="button"
        class="btn btn-blue"
        @click.prevent="$refs.successModal.close"
      >
        Okay
      </button>
    </modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // groups: 14,
      providers: {
        mastercard: {
          logo: require('@/assets/mastercard.svg'),
          color: 'red',
        },
        visa: {
          logo: require('@/assets/visa-blue.svg'),
          color: 'blue',
        },
        default: {
          logo: require('@/assets/credit-card.svg'),
          color: 'gray',
        },
      },

      access: require('@/assets/access.svg'),
      mastercard: require('@/assets/mastercard.svg'),
      visaBlue: require('@/assets/visa-blue.svg'),

      wallet: this.$options.resource([]),
      cards: this.$options.resource([]),
      repaymentCards: this.$options.resource([]),
      groups: this.$options.resource([]),
      form: this.$options.basicForm([]),
    };
  },
  computed: {
    availableCredit() {
      return this.wallet.data?.credit || 0;
    },
    debitBalance() {
      return this.wallet.data?.debit || 0;
    },
    firstGroup() {
      return this.groups.data?.[0];
    },
    secondGroup() {
      return this.groups.data?.[1];
    },
    lastCard() {
      return this.cards.data[this.cards.data.length - 1];
    },
  },
  beforeMount() {
    this.getWallet();
    this.getCards();
    this.getGroups();
    this.getRepaymentCards();
  },
  methods: {
    getProvider(cardType) {
      switch (cardType?.toLowerCase()?.trim()) {
        case 'mastercard':
        case 'mastercard credit':
        case 'mastercard debit':
          return this.providers.mastercard;
        case 'visa':
        case 'visa credit':
        case 'visa debit':
          return this.providers.visa;
        default:
          return this.providers.default;
      }
    },
    getNominator(number) {
      return this.$options.filters.currency(number).split('.')[0];
    },
    getDenominator(number) {
      return this.$options.filters.currency(number).split('.').pop();
    },
    getCardCount(group) {
      return this.cards.data.filter((card) => card.group_id === group.id)
        .length;
    },
    getTotalDebitBalance(group) {
      return this.cards.data
        .filter((card) => card.group_id === group.id && card.type === 'debit')
        .reduce((last, card) => last + card.balance, 0);
    },
    getTotalCreditBalance(group) {
      return this.cards.data
        .filter((card) => card.group_id === group.id && card.type === 'debit')
        .reduce((last, card) => last + card.available_credit, 0);
    },
    startTransfer() {
      this.$refs.transferRequest.startTransfer();
    },

    async getWallet() {
      this.wallet.loading = true;
      await this.$get({
        url: `${this.$baseurl}/companies/wallet/${this.user.company_id}`,
        headers: this.headers,
        success: (response) => {
          this.wallet.data = response.data.data;
        },
      });
      this.wallet.loading = false;
      this.wallet.loaded = true;
    },
    async getCards() {
      this.cards.loading = true;
      await this.sendRequest('corporate.cards.all', {
        success: (response) => {
          this.cards.data = response.data.data;
        },
        error: (error) => {
          console.log(error);
        },
      });
      this.cards.loading = false;
    },
    async getRepaymentCards() {
      this.repaymentCards.loading = true;
      await this.sendRequest('corporate.repaymentCards.all', {
        success: (response) => {
          this.repaymentCards.data = response.data.cards;
        },
        error: (error) => {
          console.log(error);
        },
      });
      this.repaymentCards.loading = false;
    },
    async getGroups() {
      this.groups.loading = true;
      await this.sendRequest('corporate.groups.all', {
        success: (response) => {
          this.groups.data = response.data.groups;
        },
      });
      this.groups.loading = false;
    },
    async addCard({ reference }) {
      this.form.loading = true;

      await this.sendRequest('corporate.repaymentCards.new', {
        data: {
          company_id: this.user.company_id,
          reference,
        },
        success: () => {
          this.$refs.successModal.open();
          this.getRepaymentCards();
        },
        error: (error) => {
          console.log(error);
          this.form.error = error;
        },
      });

      this.form.loading = false;
    },
  },
};
</script>
